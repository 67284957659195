













































import { Vue, Component } from "vue-property-decorator";
import GrButton from "./common/GrButton.vue";
import RegisterForQuarterlyUpdateModal from "./RegisterForQuarterlyUpdateModal.vue";
import TextWrapperReplacer from "./company/robotStepperModules/TextWrapperReplacer.vue";
import LocalStorageHelpers from "~/helpers/localStorageHelpers";
import PublicApisService from "~/services/publicApisService";

@Component({
  components: {
    GrButton,
    RegisterForQuarterlyUpdateModal,
    TextWrapperReplacer,
  },
})
export default class QuarterlyUpdateBanner extends Vue {
  removeQuarterlyUpdateBanner() {
    const success = LocalStorageHelpers.setString(
      "removeQuarterlyUpdateBannerQ224",
      "true"
    );

    PublicApisService.upsertQuarterlyUpdateBannerRemoval();

    if (success) {
      this.showQuarterlyUpdateBanner = false;
    }
  }

  $refs!: Vue["$refs"] & {
    RegisterForQuarterlyUpdateModal: RegisterForQuarterlyUpdateModal;
  };

  openRegisterForQuarterlyUpdateModal() {
    this.$refs.RegisterForQuarterlyUpdateModal.open({
      isFromQrCode: false,
    });
  }

  showQuarterlyUpdateBanner = false;

  mounted() {
    // Ta bort dessa rader när vi vill visa banner
    // const test = true;
    // if (test) {
    //   return;
    // }
    // this.showQuarterlyUpdateBanner =
    //   LocalStorageHelpers.getString("removeQuarterlyUpdateBannerQ224") !==
    //   "true";
  }
}
